import { useEffect, useMemo, useState } from "react"

export interface ProductImageOffset {
  x: number | string
  y: number | string
  scale: number | string
  center: boolean
}

export const imageOffsetRegex = /x(\d{1,3})_y(\d{1,3})/i

export const toPercent = (value: number, precision = 2): string =>
  (value * 100).toFixed(precision) + "%"

export const useImageLayout = (
  url: string,
  size = 2560
): ProductImageOffset => {
  const [center, setCenter] = useState(true)

  const handleResize = () => {
    setCenter(window.innerWidth < 1024)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return useMemo(() => {
    const offset: ProductImageOffset = { x: 0, y: 0, scale: "100%", center }
    const match = url.match(imageOffsetRegex)

    if (match) {
      // Extract x and y offsets in px
      const x = parseInt(match[1], 10) || 0
      const y = parseInt(match[2], 10) || 0

      // Calculate width and height in px and store the largest value
      const w = size - x * 2
      const h = size - y * 2
      const l = w > h ? w : h

      // Calculate the delta between the largest side and width and height
      const dw = l - w
      const dh = l - h

      // Calculate the scalar values
      const s = size / l
      const sx = x / size
      const sy = y / size
      const sw = dw / size
      const sh = dh / size

      // Calculate the x and y centering based on the viewport size
      const rx = center ? 0.5 : 1.0
      const ry = center ? 0.5 : 0.5

      // Calclulate the x and y offset values
      const ox = (sx - sw * rx) * -s
      const oy = (sy - sh * ry) * -s

      // Set the scale, x and y offsets as percentages
      offset.x = toPercent(ox)
      offset.y = toPercent(oy)
      offset.scale = toPercent(s)
    }

    return offset
  }, [url, size, center])
}
