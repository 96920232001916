import { MutableRefObject, RefObject, useEffect, useState } from "react"

export function useOnScreen<T extends HTMLElement>(
  ref: MutableRefObject<T> | RefObject<T>,
  rootMargin = "0px",
  initialState = false
): boolean {
  const [isIntersecting, setIntersecting] = useState(initialState)

  useEffect(() => {
    const isIE11 = "msCrypto" in window

    if (isIE11) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin
      }
    )

    let currentRef: T

    if (ref.current) {
      currentRef = ref.current

      observer.observe(ref.current)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [ref, rootMargin]) // eslint-disable-line react-hooks/exhaustive-deps

  return isIntersecting
}
