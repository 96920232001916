import React from "react"
import classNames from "classnames"
import styles from "./placeholder.module.scss"

interface PlaceholderProps {
  ratio: Array<number>
  className?: string
}

export const Placeholder: React.FunctionComponent<PlaceholderProps> = ({
  ratio,
  children,
  className,
  ...otherProps
}) => {
  const [w, h] = ratio

  return (
    <div
      className={classNames(className, styles.outer)}
      style={{ paddingTop: `${(h / w) * 100}%` }}
      {...otherProps}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
