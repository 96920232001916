import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { SpinnerButton } from "../../../../components/button/spinner-button"
import { Sticky } from "../../../../components/sticky/sticky"
import {
  Availability,
  AvailabilityMessageMap
} from "../../product-availability-source"
import { formatColorLanguage } from "../../store-helpers"
import { Teacher } from "../../../../types/teacher"
import { ProductAuthors } from "./product-authors"
import { ProductHeaderProps } from "./product-header"
import { Prices } from "./prices"
import styles from "./product-mini-header.module.scss"

export interface ProductMiniHeaderProps extends ProductHeaderProps {
  processing: boolean
  visible: boolean
  onButtonClick: () => Promise<void>
  availability: Availability
  price: string
  compareAtPrice?: string
  language?: string
  color?: string | undefined
}

export const ProductMiniHeader: FunctionComponent<ProductMiniHeaderProps> = ({
  onButtonClick,
  product,
  processing,
  variant,
  visible,
  availability,
  price,
  compareAtPrice,
  language,
  color
}) => {
  const isAvailable = [
    Availability.available,
    Availability.backorder,
    Availability.preorder
  ].includes(availability)

  return (
    <Sticky
      className={classNames(
        styles.productMiniHeader,
        visible && styles.visible
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <img
            data-src={product.activeVariant.image || product.image}
            alt=""
            className="lazyload"
          />
        </div>
        <div className={styles.text}>
          <div className={styles.info}>
            <h5 className={styles.title}>{product.title}</h5>
            <div className={styles.authors}>
              <ProductAuthors
                authors={product.teachers as Teacher[]}
                loadAvatar={false}
              />
              <p className={styles.variant}>
                {formatColorLanguage(variant.title, color, language)}
              </p>
            </div>
          </div>
          {isAvailable && (
            <Prices
              className={styles.prices}
              price={price}
              compareAtPrice={compareAtPrice}
              size="sm"
            />
          )}
        </div>
        <div className={styles.button}>
          <SpinnerButton
            variant="primary"
            size="md"
            onClick={onButtonClick}
            disabled={!isAvailable}
            processing={processing || availability === Availability.unknown}
          >
            <span className={styles.smallButtonLabel}>
              <FormattedMessage id="store-add" />
            </span>
            <span className={styles.longButtonLabel}>
              <FormattedMessage
                id={
                  AvailabilityMessageMap[
                    isAvailable
                      ? availability ?? Availability.unknown
                      : Availability.available // NOTE: If not available - show an "ADD" button, but disabled
                  ]
                }
              />
            </span>
          </SpinnerButton>
        </div>
      </div>
    </Sticky>
  )
}
