import { FluidObject } from "gatsby-image"
import {
  ContentfulPromotionUnion,
  ContentfulPromotion,
  ContentfulPromotionProduct
} from "../../../../graphql/types"
import {
  HomePageCustomPromotion,
  HomePageProductPromotion
} from "../home-page-types"
import { mapVariantDetailsToView } from "@features/store/mappers/product-details-mapper"

const getCustomPromotion = (
  promotion: ContentfulPromotion
): HomePageCustomPromotion => ({
  overline: promotion.overline || "",
  headline: promotion.headline?.childMarkdownRemark?.html || "",
  subhead: promotion.subhead?.childMarkdownRemark?.html || "",
  image: promotion?.image?.fluid as FluidObject,
  primaryButtonText: promotion.primaryButtonText || "",
  primaryButtonLink: promotion.primaryButtonLink || "",
  primaryButtonSupportingCopy: promotion.primaryButtonSupportingCopy || "",
  secondaryButtonText: promotion.secondaryButtonText || "",
  secondaryButtonLink: promotion.secondaryButtonLink || ""
})

const getProductPromotion = (
  promotion: ContentfulPromotionProduct
): HomePageProductPromotion | undefined => {
  const product = promotion.product
    ? mapVariantDetailsToView(promotion.product)
    : null

  if (!product) return undefined

  return {
    headline: promotion.headline?.childMarkdownRemark?.html || "",
    subhead: promotion.subhead?.childMarkdownRemark?.html || "",
    buttonText: promotion.buttonText || "",
    showSecondaryLink: promotion.showSecondaryLink || false,
    secondaryLinkText: promotion.secondaryLinkText || "",
    product
  }
}

export const mapPromotion = (gqlPromotion: ContentfulPromotionUnion) => {
  const customPromotion = getCustomPromotion(
    gqlPromotion as ContentfulPromotion
  )
  const productPromotion = getProductPromotion(
    gqlPromotion as ContentfulPromotionProduct
  )

  return {
    customPromotion,
    productPromotion
  }
}
