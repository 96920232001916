import React, { FunctionComponent } from "react"
import classNames from "classnames"
import Image, { FluidObject } from "gatsby-image"
import styles from "./promo-header.module.scss"
import { Link } from "@components/link/link"
import { Button } from "@components/button/button"
import { Placeholder } from "@components/placeholder/Placeholder"
import { Markdown } from "@components/markdown/markdown"

export interface PromoHeaderProps {
  overline: string
  headline: string
  subhead: string
  primaryButtonText: string
  primaryButtonLink: string
  primaryButtonSupportingCopy: string
  secondaryButtonText?: string
  secondaryButtonLink?: string
  image?: FluidObject
}

export const PromoHeader: FunctionComponent<PromoHeaderProps> = ({
  overline,
  headline,
  subhead,
  primaryButtonText,
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  primaryButtonSupportingCopy,
  image
}) => {
  const hasSecondaryButton = Boolean(secondaryButtonText && secondaryButtonLink)

  const reactMarkdownProps = {
    disallowedElements: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
    unwrapDisallowed: true,
    className: styles.body
  }

  const innerContent = (
    <>
      <div className={styles.imageArea}>
        {image ? (
          <Image fluid={image} className={styles.image} />
        ) : (
          <Placeholder ratio={[1, 1]} className={styles.placeholder} />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.overline}>{overline}</div>
        <h2 className={styles.title}>
          <Markdown content={headline} {...reactMarkdownProps} />
        </h2>
        <h3 className={styles.subtitle}>
          <Markdown content={subhead} {...reactMarkdownProps} />
        </h3>
        <h3 className={styles.subtitle}></h3>
        <div className={styles.buttonAreaWrapper}>
          <Button
            variant="primary"
            size="md"
            className={styles.button}
            to={primaryButtonLink}
          >
            {primaryButtonText}
          </Button>
          {hasSecondaryButton ? (
            <Button
              variant="secondary"
              size="md"
              className={classNames(styles.button, styles.secondary)}
              to={secondaryButtonLink}
            >
              {secondaryButtonText}
            </Button>
          ) : primaryButtonSupportingCopy ? (
            <div className={styles.supportingCopy}>
              {primaryButtonSupportingCopy}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )

  return (
    <div className={styles.promoHeaderWrapper}>
      {hasSecondaryButton ? (
        <div className={styles.promoHeader}>{innerContent}</div>
      ) : (
        <Link className={styles.promoHeader} to={primaryButtonLink}>
          {innerContent}
        </Link>
      )}
    </div>
  )
}
