/* eslint-disable no-constant-condition */
import React, { FunctionComponent } from "react"
import { AvatarList } from "../../../../components/avatar/avatar-list"
import { Avatar } from "../../../../components/avatar/avatar"
import { Link } from "../../../../components/link/link"
import { Teacher } from "../../../../types/teacher"
import styles from "./product-authors.module.scss"

export interface ProductAuthorsProps {
  authors: Teacher[]
  loadAvatar?: boolean
}

export const ProductAuthors: FunctionComponent<ProductAuthorsProps> = ({
  authors,
  loadAvatar = true
}) => {
  if (authors.length === 0) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      {authors.length === 1 ? (
        <div className={styles.teacher}>
          {loadAvatar && (
            <Avatar
              image={authors[0].image}
              link={authors[0].link}
              size="sm"
              outline
            />
          )}
          <Link to={authors[0].link}>{authors[0].name}</Link>
        </div>
      ) : (
        loadAvatar && (
          <AvatarList
            listClassName={styles.teacher}
            itemClassName={styles.item}
            namesClassName={styles.name}
            avatars={authors}
            limit={4}
            showNames
            size="sm"
          />
        )
      )}
    </div>
  )
}
