import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { ProductPrices } from "../../store-types"
import styles from "./prices.module.scss"
import { showCompareAtPrice } from "@core/prices"
import { formatPrice } from "@utils/formatPrice"

export type PricesSize = "md" | "sm"

export interface PricesProps extends ProductPrices {
  className?: string
  size?: PricesSize
  discountDataTestId?: string
  itemPriceDataTestId?: string
  compareAtPrice?: string
  price: string
}

export const Prices: FunctionComponent<PricesProps> = ({
  className,
  price,
  compareAtPrice = "",
  discountDataTestId = "",
  itemPriceDataTestId = "",
  size = "md"
}) => {
  return (
    <span className={classNames(styles.prices, className, styles[size])}>
      {showCompareAtPrice(price, compareAtPrice) && (
        <span
          className={classNames(styles.discounted)}
          data-testid={discountDataTestId}
        >
          {formatPrice(compareAtPrice)}
        </span>
      )}
      <span data-testid={itemPriceDataTestId}>{formatPrice(price)}</span>
    </span>
  )
}
